export default {

    getAnomalyReasonAsText: (anomalyReasonValue) => {
        switch (anomalyReasonValue) {
            case 1:
                return "Position";
            case 2:
                return "Course over ground";
            case 3:
                return "Position & Course over ground"
            case 4:
                return "Speed over ground";
            case 5:
                return "Position & Speed over ground";
            case 6:
                return "Course over ground & Speed over ground";
            case 7:
                return "Position, Course over ground & Speed over ground";
            case 8:
                return "Position Jump";
            case 9:
                return "Position & Position Jump";
            case 10:
                return "Course over ground & Position Jump";
            case 11:
                return "Position, Course over ground & Position Jump";
            case 12:
                return "Speed over ground & Position Jump";
            case 13:
                return "Position, Speed over ground & Position Jump";
            case 14:
                return "Course over ground, Speed over ground & Position Jump";
            case 15:
                return "Position, Course over ground, Speed over ground & Position Jump";
            default:
                return "N/A";
        }
    }
}