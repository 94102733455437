<template>
  <v-navigation-drawer class="text-light-blue">
    <v-divider></v-divider>
    <br/>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title >Map Selection</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div class="map-overlay top">
      <div class="map-overlay-inner">
        <fieldset>
          <LayerSelector :layers="layers" :incidentProfiles="incidentProfiles"
                         @layerEvent="layerEvent" @anomalyFilter="anomalyFilter"
                         @incidentProfileFilter="incidentProfileFilter">
          </LayerSelector>
        </fieldset>

      </div>
    </div>
      <v-autocomplete
            clearable
            v-model="select"
            v-model:search="search"
            :loading="loadingMmsi"
            :items="mmsiArray"
            density="comfortable"
            color="info"
            hide-no-data
            hide-details
            label="MMSI"
            style="max-width: 300px; --v-medium-emphasis-opacity: 1; margin: 0px; margin-right: 0px !important; margin-left: 0px !important;"
        ></v-autocomplete>
      <div v-if="countedMmsiAnomalies !== undefined">
        <v-alert v-if="countedMmsiAnomalies === 0" type="error" text="No anomalies found in time period">
        </v-alert>
        <v-alert v-if="countedMmsiAnomalies !== 0" type="info">
          Found {{ countedMmsiAnomalies }} anomalies in time period
        </v-alert>
      </div>
    <div class="bottom">
      <JsonCSV :data="anomalies" :labels="labels" :name="filename">
        <v-list-item>
          <v-list-item-content>
            <v-btn color="info" prepend-icon="mdi-download" block  v-if="!loadingAnomaly">
              Download Anomalies
            </v-btn>
            <v-btn color="info" prepend-icon="mdi-download" block  v-if="loadingAnomaly" disabled>
              Download Anomalies
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </JsonCSV>
    </div>
  </v-navigation-drawer>
</template>

<script>
import LayerSelector from "@/components/LayerSelector";
import "maplibre-gl/dist/maplibre-gl.css";
import JsonCSV from "vue-json-csv";
import moment from "moment";
import authorizedFetch from "@/services/AuthorizedFetch";

export default {
  name: "MenuBar",
  components: { LayerSelector, JsonCSV },
  props: ["layers", "incidentProfiles", "user", "countedMmsiAnomalies", "startDate", "endDate",
          "consecutive"],
  data() {
    return {
      loadingMmsi: false,
      search: null,
      select: null,
      mmsiArray: [],
      anomalies: [],
      labels: [],
      filename: '',
      loadingAnomaly: false
    }
  },
  watch: {
    search(val) {
      if (val.length > 2) {
        val && val !== this.select && this.querySelections(val)
      }
    },
    select(val) {
      console.log('selected: ' + val)
      this.$emit('selectedMmsi', val)
    },
    startDate(val) {
      console.log('start date changed: ' + val)
      this.loadingAnomaly = true
      let start = moment(val).format('YYYY-MM-DDTHH:mm:ss')
      let end = moment(this.endDate).format('YYYY-MM-DDTHH:mm:ss')
      authorizedFetch('/api/anomaly/' + this.consecutive + '/' + start + "/" + end)
          .then((response) => response.json())
          .then((data) => {
              this.anomalies = data
              this.loadingAnomaly = false
          })
      this.filename = 'anomalies-' + start + '-' + end  + '.csv'
    }
  },
  methods: {
    layerEvent(event) {
      this.$emit('layerEvent', event)
    },
    anomalyFilter(anomalyReason) {
      this.$emit('anomalyFilter', anomalyReason)
    },
    incidentProfileFilter(incidentProfile) {
      this.$emit('incidentProfileFilter', incidentProfile)
    },
    querySelections (v) {
      this.loadingMmsi = true
      authorizedFetch('/api/anomaly/search/mmsi/' + v)
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            this.loadingMmsi = false
            this.mmsiArray = data
          })
    }

  }
}
</script>

<style scoped>

.map-overlay {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  width: 100%;
}

.map-overlay .map-overlay-inner {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.map-overlay-inner fieldset {
  border: none;
  padding: 0;
  margin: 0 0 10px;
}

.map-overlay-inner fieldset:last-child {
  margin: 0;
}

.map-overlay-inner select {
  width: 100%;
}

.map-overlay-inner label {
  display: block;
  font-weight: bold;
  margin: 0 0 5px;
}

.map-overlay-inner button {
  background-color: #3386c0;
  color: white;
  display: inline-block;
  height: 20px;
  border: none;
  cursor: pointer;
}

.map-overlay-inner button:focus {
  outline: none;
}

.map-overlay-inner button:hover {
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.bottom {
  position: absolute;
  bottom: 0;
}
.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: #2196F3 !important;
}

</style>