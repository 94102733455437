<template>
  <v-app>
  <v-navigation-drawer>
    <v-card
        title="Incident"
        :subtitle="properties.id"
        variant="outlined"
    >
      <v-list>
        <v-list-item
            title="Profile ID"
            :subtitle="properties.profile"
        >
        </v-list-item>
        <v-list-item
            title="Start Time"
            :subtitle="properties.start_time"
        >
        </v-list-item>
        <v-list-item
            title="End Time"
            :subtitle="properties.end_time"
        >
        </v-list-item>
      </v-list>
      <v-list>
        <v-subheader>Vessels involved</v-subheader>
        <v-list-item
            v-for="vessel in vesselsWithAnomalies"
            :key="vessel.mmsi"
            :title="vessel.name"
            :subtitle="vessel.mmsi">
          {{vessel.anomaliesLast24hrs}} anomalies last 24 hours before incident
          <template v-slot:append>
            <v-btn
                color="grey-lighten-1"
                icon="mdi-information"
                variant="text"
                v-on:click="showAnomaliesForVessel(vessel.mmsi)"
            ></v-btn>
          </template>
        </v-list-item>
      </v-list>
    </v-card>
  </v-navigation-drawer>
  <v-main>
    <IncidentMap :incident="incident"
                 :anomalies="anomalies"
                 :highlightMmsi="highlightMmsi"
                 :exclusion-areas="exclusionAreas"
                  @mapLoaded="mapLoaded">
    </IncidentMap>
  </v-main>
  </v-app>
</template>

<script>

import IncidentMap from "@/components/IncidentMap";
import authorizedFetch from "@/services/AuthorizedFetch";
export default {
  name: 'IncidentView',
  props: ["id"],
  components: {
    IncidentMap
  },
  data() {
    return {
      incident: {},
      properties: {},
      vessels: [],
      vesselsWithAnomalies: [],
      anomalies: {
        'type': 'FeatureCollection',
        'features': []
      },
      numberOfConsecutives: 3,
      highlightMmsi: 0,
      exclusionAreas: {
        'type': 'FeatureCollection',
        'features': []
      }
    }
  },
  methods: {
    async loadIncident(incidentId) {
      authorizedFetch('/api/incident/id/' + incidentId)
          .then(response => response.json())
          .then((data) => {
            console.log(data)
            this.incident = data
            this.properties = data.properties
          })
    },
    async loadVessels(incidentId) {
      authorizedFetch('/api/incident/neighbours/' + incidentId)
          .then(response => response.json())
          .then((data) => {
            this.vessels = data
          })
    },
    async loadAnomalies(incidentId) {
      console.log("loading anomalies")
      authorizedFetch('/api/incident/anomalies/geojson/' + incidentId)
        .then(response => response.json())
        .then((data) => {
            let features = []
            for (let i = 0; i < data.length; i++) {
              try {
                let feature = JSON.parse(data[i])

                let coordinates = feature.geometry.coordinates
                if (coordinates[0] > 180) {
                  feature.geometry = {
                    'type': feature.properties.valid_geom.type,
                    'coordinates': feature.properties.valid_geom.coordinates
                  }
                }
                features.push(feature)
              } catch (e) {
                console.log(e)
              }
            }
            console.log(features)
            this.anomalies = {
              'type': 'FeatureCollection',
              'features': features
            }
        })
    },
    async getExclusionAreas() {
      authorizedFetch('/api/incident/exclusion-areas')
          .then((response) => response.json())
          .then((data) => {
            let features = []

            for (let i = 0; i < data.length; i++) {
              try {
                let feature = JSON.parse(data[i])
                features.push(feature)
              } catch (e) {
                console.log(e)
              }
            }
            this.exclusionAreas = {
              'type': 'FeatureCollection',
              'features': features
            }
          })
    },
    showAnomaliesForVessel(mmsi) {
      this.highlightMmsi = mmsi
    },
    mapLoaded() {
      console.log('map is loaded')
      this.loadAnomalies(this.id)
      this.getExclusionAreas();
    }
  },
  created() {
    this.loadIncident(this.id)
    this.loadVessels(this.id)
  },
  watch: {
    vessels(newVessels) {
      newVessels.forEach(vessel => {
        authorizedFetch('/api/anomaly/mmsi/' + vessel.mmsi + '/' + this.numberOfConsecutives + '/' + this.properties.start_time + '/24')
            .then(response => response.json())
            .then((data) => {
              let vesselWithAnomalies = {
                'mmsi': vessel.mmsi,
                'name': vessel.vesselName,
                'anomaliesLast24hrs': data
              }

              this.vesselsWithAnomalies.push(vesselWithAnomalies)
            })
      })

    }
  }
}
</script>