<template>
  <div class="reason-list" @click="toggleIncidentProfileFilter">
    <input type="checkbox" id="show-incident-profiles" v-model="show">
    <label>Filter incident profiles</label>
  </div>
  <nav class="nav-anomaly" id="profile-list" v-if="show">
    <div class="reason-list" v-for="profile in incidentProfiles" v-bind:key="profile.id"
         @click="toggleIncidentProfile(profile)"
         :title="'Vessels: ' + profile.vessels + '\n' +
            'Time in minutes: '+ profile.timeInMinutes + '\n' +
            'Radius: ' + profile.radius + '\n' +
            'Number of consecutive messages: ' + profile.numberOfConsecutives">
      <input type="checkbox" id="profile.id" name="profile.extendedName" v-model="profile.checked" >
      <label>{{profile.id}} - {{ profile.extendedName }}</label>
    </div>
  </nav>
</template>

<script>
export default {
  name: "IncidentProfileFilter",
  props: ["incidentProfiles"],
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleIncidentProfileFilter() {
      this.show = !this.show
      if (!this.show) {
        this.incidentProfiles.forEach(profile => {
          profile.checked = true;
        })
        this.$emit("incidentProfileFilter", this.getIncidentProfileFilterValues())
      }
    },
    toggleIncidentProfile(profile) {
      profile.checked = !profile.checked
      this.$emit("incidentProfileFilter", this.getIncidentProfileFilterValues())
    },
    getIncidentProfileFilterValues: function () {
      let filterValues = new Set()
      this.incidentProfiles.forEach(profile => {
        if (profile.checked) {
          filterValues.add(profile.id)
        }
      })
      return filterValues;
    }
  }
}
</script>

<style scoped>
.filter-group input[type='checkbox']:first-child + label {
  border-radius: 3px 3px 0 0;
}

.filter-group label:last-child {
  border-radius: 0 0 3px 3px;
  border: none;
}

.filter-group input[type='checkbox'] {
  display: none;
}

.filter-group input[type='checkbox'] + label {
  background-color: #fff;
  display: block;
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.filter-group input[type='checkbox'] + label {
  background-color: #fff;
  text-transform: capitalize;
}

.filter-group input[type='checkbox'] + label:hover,
.filter-group input[type='checkbox']:checked + label {
  background-color: #ADD8E6;
}

.filter-group input[type='checkbox']:checked + label:before {
  content: '✔';
  margin-right: 5px;
}

#reason-list a {
  color: white;
  height: 30px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}
#reason-list a:last-child {
  border: none;
}
.reason-list input[type='checkbox'] {
  display: none;
}

.reason-list input[type='checkbox'] + label {
  background-color: #fff;
  display: block;
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.reason-list input[type='checkbox'] + label {
  background-color: #fff;
  text-transform: capitalize;
}

.reason-list input[type='checkbox'] + label:hover,
.reason-list input[type='checkbox']:checked + label {
  background-color: #ADD8E6;
}

.reason-list input[type='checkbox']:checked + label:before {
  content: '✔';
  margin-right: 5px;
}
.nav-anomaly {
  padding: 0px;
}
</style>