import store from "@/store";

const SubscriptionService = {
    async saveSub(subscription) {
        const token = store.getters.getToken;
        fetch('api/subscribe/save/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(subscription),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
}
export default SubscriptionService