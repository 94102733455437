
// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as allComponents from "vuetify/lib/components";
import { VDataTableServer } from "vuetify/labs/components";
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const customLightTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#3386c0',
        secondary: '#03DAC6',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}

export default createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
    theme: {
        defaultTheme: 'customLightTheme',
        themes: {
            customLightTheme
        }
    },
    components: {
        VDataTableServer, ...allComponents
    },
    defaults: {
            VDataTableServer: {
                fixedHeader: true,
                noDataText: 'Results not found',
            },
    },
    directives
})
