<template>
  <div class="label-container">
  <div id="label">
    Showing from: {{ dateStart }}<br/>
    to: {{dateEnd}}<br/>
    Zoomlevel: {{ zoom }}
  </div>
  </div>
</template>

<script>
export default {
  name: "StatusBar",
  props: {
    dateStart: String,
    dateEnd: String,
    zoom: Number
  }
}
</script>

<style scoped>
.label-container {
  position: absolute;
  bottom: 20px;
  right: 2px;
  z-index: 1;
}

.label-container > * {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 11px;
  line-height: 18px;
  display: block;
  margin: 0;
  padding: 5px 10px;
  border-radius: 3px;
}
</style>