import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CONFIG from "@/config";
import vuetify from "@/plugins/vuetify";
import JsonCSV from 'vue-json-csv'

import {
    applyPolyfills,
    defineCustomElements
} from '@aws-amplify/ui-components/loader';
import Amplify from '@aws-amplify/auth';

Amplify.configure({
    Auth: CONFIG.Auth,
});
applyPolyfills().then(() => {
    defineCustomElements(window);
});
/*
const vuetify = createVuetify({
    components,
    directives,
    theme: {
        dark: true,
        themes: {
            light: {
                primary: '#00387A',
                secondary: '#FF9500',
                accent: '#FFE473',
                error: '#FF5252',
                info: '#B2C2D6',
                success: '#4CAF50',
                warning: '#FFC107'
            }
        }
    }
})*/

const fetchWithAuth = (url, options) => {
    const authToken = localStorage.getItem('authToken');
    console.log('authToken:', authToken);

    const headers = {};
    if (authToken) {
        headers['Authorization'] = 'Bearer ' + authToken;
    }
    console.log('headers:', headers);

    options = options || {};
    options.headers = Object.assign({}, options.headers, headers);
    console.log('options:', options);

    return fetch(url, options);
};


const app = createApp(App);
app.config.isCustomElement = tag => tag.startsWith('amplify-');
app.config.globalProperties.$http = fetchWithAuth;
app.use(store).use(router).use(vuetify).use(JsonCSV).mount('#app');
