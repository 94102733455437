<template>
  <div id="mapContainer">
  </div>
</template>

<script>

import "maplibre-gl/dist/maplibre-gl.css";
import maplibregl from 'maplibre-gl';
import MapService from "@/services/MapService";
import AnomalyReasonText from "@/utils/AnomalyReasonText";

export default {
  name: "IncidentMap",
  props: ["incident", "anomalies", "highlightMmsi", "exclusionAreas"],
  data() {
    return {
      map: {},
      highlightMmsiLayer: undefined
    }
  },
  methods: {
    setupMap: function () {
      const cmap = new maplibregl.Map({
        container: 'mapContainer',
        style: 'https://api.maptiler.com/maps/bright-v2/style.json?key=wVAa9X69krko1sqQfQmc',
        center: [12.2, 65.463],
        zoom: 5
      });
      cmap.on('load', async () => {
        const fishingZoneSourceId = 'fiskerigrense'
        const fishingZoneSource = MapService.getFishingZoneSource()
        const fishingZoneLayer = MapService.getFishingZoneLayer(fishingZoneSourceId)
        cmap.addSource(fishingZoneSourceId, fishingZoneSource)
        cmap.addLayer(fishingZoneLayer)

        const topoSourceId = 'kartverket-topo'
        const topoSource = MapService.getKartverketTopoSource()
        const topoLayer = MapService.getKartverketTopoLayer(topoSourceId)
        cmap.addSource(topoSourceId, topoSource)
        cmap.addLayer(topoLayer)

        cmap.addSource('incident', {
          'type': 'geojson',
          'data': this.incident
        })
        cmap.addLayer({
          'id': 'incident',
          'type': 'fill',
          'source': 'incident',
          'layout': {},
          'paint': {
            'fill-color': MapService.getIncidentColor(),
            'fill-opacity': 0.8
          }
        })
        cmap.addSource(MapService.getAnomalySource(), {
          'type': 'geojson',
          'data': this.anomalies
        })
        let anomalyLayerId = MapService.getAnomalyLayerId()
        const anomalyGeoJsonLayer = MapService.getAnomalyLayer(MapService.getAnomalySource())
        cmap.addLayer(anomalyGeoJsonLayer)

        this.highlightMmsiLayer = MapService.getHighlightLayer(MapService.getAnomalySource())
        cmap.addLayer(this.highlightMmsiLayer)

        cmap.addSource(MapService.getExclusionSource(), {
          'type': 'geojson',
          'data': this.exclusionAreas
        })
        cmap.addLayer(MapService.getExclusionLayer(MapService.getExclusionSource()))

        cmap.on('click', anomalyLayerId, function (e) {
          var coordinates = e.features[0].geometry.coordinates.slice();

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          const mmsi = e.features[0].properties?.mmsi;
          const properties = e.features[0].properties;
          fetch("/aisd/vesselInformation/" + mmsi)
              .then((response) => response.json())
              .then((data) => {
                let vesselName = data.vesselName;
                if (vesselName === undefined) {
                  vesselName = "N/A";
                }
                var info = '<h1>MMSI: ' + mmsi + '</h1>';
                info += 'Name: ' + vesselName + '<br/>';
                info += 'ID: ' + properties.id + '<br/>';
                info += 'Reason: ' + AnomalyReasonText.getAnomalyReasonAsText(properties.anomaly_reason) + '<br/>';
                info += 'SOG: ' + properties.sog + '<br/>';
                info += 'COG: ' + properties.cog + '<br/>';
                info += 'Number of consecutive: ' + properties.number_of_consecutive_messages + '</br>';
                info += 'Start: ' + properties.start_time + '</br>';
                info += 'End: ' + properties.end_time + '</br>';
                new maplibregl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(info)
                    .addTo(cmap);
              })
              .catch(error => console.warn(error)
              );
        })
        cmap.on('mouseenter', anomalyLayerId, function () {
          cmap.getCanvas().style.cursor = 'pointer'
        });
        cmap.on('mouseleave', anomalyLayerId, function () {
          cmap.getCanvas().style.cursor = ''
        });
        this.fitToBounds(cmap)
        this.$emit('mapLoaded')
      })
      this.map = cmap
    },

    fitToBounds(cmap) {
      let coordinates
      if (this.incident.geometry.type === 'Polygon') {
        coordinates = this.incident.geometry.coordinates[0]
        let bounds = coordinates.reduce(function(bounds, coords) {
          return bounds.extend(coords)
        }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]))
        cmap.fitBounds(bounds, { padding: 20})
      } else {
        console.log('no polygon')
      }

    }
  },
  mounted() {
    this.setupMap()
    console.log(this.incident)
  },
  watch: {
    anomalies(anomalies) {
      let source = this.map.getSource(MapService.getAnomalySource())
      if (source === undefined) {
        this.map.addSource(MapService.getAnomalySource(), {
            'type': 'geojson',
            'data': this.anomalies
        })
      } else {
        source.setData(anomalies)
      }
    },
    highlightMmsi(mmsi) {
      this.map.setFilter(MapService.getHighlightLayerId(),
          [
            "any",
            ['all', ['==', 'mmsi', mmsi]]
          ]
      );
    },
    exclusionAreas(exclusionAreas) {
      let source = this.map.getSource(MapService.getExclusionSource())
      if (source === undefined) {
        this.map.addSource(MapService.getExclusionSource(), {
          'type': 'geojson',
          'data': this.exclusionAreas
        })
      } else {
        this.map.getSource(MapService.getExclusionSource()).setData(exclusionAreas);
      }
      console.log('new exclusion area')
    }
  }
}
</script>

<style scoped>
#mapContainer {
  width: 100%;
  height: 100%;
}
</style>