<template>

    <MenuBar :layers="layers" :incidentProfiles="profiles" :user="user"
                        :countedMmsiAnomalies="countedMmsiAnomalies"
                        :startDate="date" :endDate="endDate"
                        :consecutive="consecutive"
                        @anomalyFilter="anomalyFilter"
                        @incidentProfileFilter="incidentProfileFilter"
                        @neighbourValues="updateValues" @layerEvent="layerEvent"
                        @selectedMmsi="setMmsi">
    </MenuBar>
  <v-main>
  <div id="mapContainer">
    <StatusBar :dateStart="date" :dateEnd="endDate" :zoom="zoomLevel"></StatusBar>
    <DistinctMmsiBar :startDate="date"></DistinctMmsiBar>
  </div>

  <div id="lineContainer">
    <DataSelection :chartData="chartData" :loaded="loaded" @range="rangeChart" @date="dateChart"
                   @clicked="dateSelect">
    </DataSelection>
  </div>

  </v-main>
</template>
<script>

import "maplibre-gl/dist/maplibre-gl.css";
import maplibregl from 'maplibre-gl';
import moment from 'moment'
import warningImage from '../assets/warning.png';

import MenuBar from '@/components/MenuBar.vue';
import AnomalyReasonText from "@/utils/AnomalyReasonText";
import { Auth } from "aws-amplify";
import StatusBar from "@/components/StatusBar";
import MapService from "@/services/MapService";
import DataSelection from "@/components/DataSelection";

import api from "@/services/Api";
import authorizedFetch from "@/services/AuthorizedFetch";
import DistinctMmsiBar from "@/components/DistinctMmsiBar";

const ANOMALY_LAYER_ID = 'anomaly-vector-l'
const INCIDENT_LAYER_ID = 'incident-vector-l'
const INCIDENT_LAYER_ID_SYMBOL = 'incident-vector-l-symbol'
const INCIDENT_COLOR = '#f65d4c'
const ANOMALY_COLOR = '#E19C35'

export default {
  name: "MapView",
  components: {DistinctMmsiBar, DataSelection, StatusBar, MenuBar },
  data() {
    return {
      center: [12.2, 65.463],
      map: undefined,
      layerControl: {},
      chartData: {},
      anomalyLayer: {},
      layerGroup: {},
      date: undefined,
      endDate: undefined,
      layers: [],
      minutes: 300,
      radius: 1000,
      consecutive: 3,
      vessels: 3,
      zoomLevel: 5,
      profiles: [],
      hours: 24,
      user: 'test',
      highlightMmsiLayer: undefined,
      mmsi: 0,
      mmsiAnomalies: {
        'type': 'FeatureCollection',
        'features': []
      },
      countedMmsiAnomalies: undefined,
      loaded: false,
      exclusionAreas: {
        'type': 'FeatureCollection',
        'features': []
      }
    }
  },
  methods: {
    setupMap: function () {
      const cmap  = new maplibregl.Map({
        container: 'mapContainer',
        style: 'https://api.maptiler.com/maps/bright-v2/style.json?key=wVAa9X69krko1sqQfQmc',
        center: this.center,
        zoom: this.zoomLevel

      });

      cmap.on('load', async () => {
        cmap.loadImage(warningImage, (error, image) => {
          if (error) {
              console.log('error finding image')
              throw error;
          }
          cmap.addImage('incident-icon', image);
        });
        const fishingZoneSourceId = 'fiskerigrense'
        const fishingZoneSource = MapService.getFishingZoneSource()
        const fishingZoneLayer = MapService.getFishingZoneLayer(fishingZoneSourceId)
        cmap.addSource(fishingZoneSourceId, fishingZoneSource)
        cmap.addLayer(fishingZoneLayer)

        const topoSourceId = 'kartverket-topo'
        const topoSource = MapService.getKartverketTopoSource()
        const topoLayer = MapService.getKartverketTopoLayer(topoSourceId)
        cmap.addSource(topoSourceId, topoSource)
        cmap.addLayer(topoLayer)

        if (this.date === undefined) {
            this.date = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        }

        if (this.endDate === undefined) {
          this.endDate = moment(this.date).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
        }

        cmap.addSource('anomaly-vector', {
          'type': 'vector',
          'tiles': [
             window.location.origin + '/vector/anomaly/' + this.date + '/' + this.consecutive + '/{z}/{x}/{y}'
          ]
        })
        cmap.addLayer(
            {
              'id': ANOMALY_LAYER_ID,
              'type': 'circle',
              'source': 'anomaly-vector',
              'source-layer': 'anomaly',
              'paint': {
                'circle-color': ANOMALY_COLOR,
                'circle-stroke-color': '#808080',
                'circle-stroke-width': 1,
                'circle-radius': 5.0,
                'circle-opacity': 0.9
              }
            }
        )

        cmap.addSource('incident-vector', {
          'type': 'vector',
          'tiles': [
            window.location.origin + '/vector/incident/' + this.date +  '/{z}/{x}/{y}'
          ]
        })
        console.log('adding incident vector')
        this.addLayer(INCIDENT_LAYER_ID);
        console.log('adding layer id')
        cmap.on('zoom',async () => {
          this.zoomLevel = Math.round(this.map.getZoom());
        })

        let anomalyLayer = { id: ANOMALY_LAYER_ID, name: 'Anomaly', value: true }
        this.layers.push(anomalyLayer)

        let incidentLayer = { id: INCIDENT_LAYER_ID, name: 'Incident', value: true }
        this.layers.push(incidentLayer)

        let highlightSourceId = MapService.getMmsiSource()
        cmap.addSource(highlightSourceId, {
          'type': 'geojson',
          'data': this.mmsiAnomalies
        })
        this.highlightMmsiLayer = MapService.getHighlightLayerWithoutFilter(highlightSourceId)
        cmap.addLayer(this.highlightMmsiLayer)
        cmap.addSource(MapService.getExclusionSource(), {
          'type': 'geojson',
          'data': this.exclusionAreas
        })
        cmap.addLayer(MapService.getExclusionLayer(MapService.getExclusionSource()))
      });
      cmap.on('click', ANOMALY_LAYER_ID, function (e) {
        var coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const mmsi = e.features[0].properties?.mmsi;
        const properties = e.features[0].properties;
        fetch("/aisd/vesselInformation/" + mmsi)
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              let vesselName = data.vesselName;
              if (vesselName === undefined) {
                vesselName = "N/A";
              }
              console.log(vesselName);
              var info = '<h1>MMSI: ' + mmsi + '</h1>';
              info += 'Name: ' + vesselName + '<br/>';
              info += 'ID: ' + properties.id + '<br/>';
              info += 'Reason: ' + AnomalyReasonText.getAnomalyReasonAsText(properties.anomaly_reason) + '<br/>';
              info += 'SOG: ' + properties.sog + '<br/>';
              info += 'COG: ' + properties.cog + '<br/>';
              info += 'Number of consecutive: ' + properties.number_of_consecutive_messages + '</br>';
              info += 'Start: ' + properties.start_time + '</br>';
              info += 'End: ' + properties.end_time + '</br>';
              new maplibregl.Popup()
                  .setLngLat(coordinates)
                  .setHTML(info)
                  .addTo(cmap);
            })
            .catch(error => console.warn(error)
            );

      });
      cmap.on('mouseenter', ANOMALY_LAYER_ID, function () {
        cmap.getCanvas().style.cursor = 'pointer';
      });
      cmap.on('mouseleave', ANOMALY_LAYER_ID, function () {
        cmap.getCanvas().style.cursor = '';
      });

      cmap.on('click', INCIDENT_LAYER_ID_SYMBOL, function (e) {
        let coordinates = e.lngLat;
        let incidentId = e.features[0].properties.id
        let properties = e.features[0].properties
        let profileId = e.features[0].properties.profile
        let html = '';
        authorizedFetch("/api/incident/neighbours/" + incidentId)
                .then((response) => response.json())
                .then((data) => {
                  var profileId = properties.profile
                  var info = '<h1>ID: ' + incidentId + '</h1>'
                  info += 'Profile: ' + profileId + '<br/>'
                  info += 'Start: ' + properties.start_time + '</br>'
                  info += 'End: ' + properties.end_time + '</br>'
                  info += 'Vessels involved in incident:  </br>'
                  for (let i = 0; i < data.length; i++) {
                    console.log(data[i]);
                    let vessel = data[i]
                    info += 'MMSI: ' + vessel.mmsi + ', Name: ' + vessel.vesselName + '</br>'
                  }
                  info += '<hr class="solid">'

                  new maplibregl.Popup()
                      .setLngLat(coordinates)
                      .setHTML(info + html)
                      .addTo(cmap)

                })
                .then(authorizedFetch('/api/incident/profile/' + profileId)
                    .then((response) => response.json())
                    .then((data) => {
                      let profile = data
                      html += 'Found using profile: ' + profileId + ' with: </br>'
                      html += 'Name: ' + profile.extendedName + '</br>'
                      html += 'Vessels: ' + profile.vessels + '</br>'
                      html += 'Radius: ' + profile.radius + '</br>'
                      html += 'Time in mins: ' + profile.timeInMinutes + '</br>'
                      html += 'Consecutive errors: ' + profile.numberOfConsecutives + '</br>'
                      html += '<h3><a href="/vIncident/' + incidentId + '">View Full</a></h3>'
                    }))
      });

      cmap.on('mouseenter', INCIDENT_LAYER_ID_SYMBOL, function () {
        cmap.getCanvas().style.cursor = 'pointer';
      });
      cmap.on('mouseleave', INCIDENT_LAYER_ID_SYMBOL, function () {
        cmap.getCanvas().style.cursor = '';
      });

      this.map = cmap;

    },
    dateSelect(index) {
      let label = this.chartData.labels[index]
      this.date = label
      if (index + 1 >= this.chartData.labels.length) {
        this.endDate = moment(this.date).add(this.hours, 'hours').format('YYYY-MM-DDTHH:mm:ss')
      } else {
        this.endDate = this.chartData.labels[index + 1]
      }
      this.setTiles()
    },
    rangeChart(range) {
      this.hours = range.value
      this.loadChartValues(this.hours, true)
    },
    dateChart(date) {
      this.date = moment(date[0]).format("YYYY-MM-DDTHH:mm:ss")
      if (date[1] === null || date[1] === undefined) {
        date[1] = moment(date[0]).add(this.hours, 'hours').format("YYYY-MM-DDTHH:mm:ss");
      }
      this.endDate = moment(date[1]).format("YYYY-MM-DDTHH:mm:ss")
      this.setTiles()
      this.loadChartValuesInTimeRange(this.hours)
    },
    addLayer(id) {
       if (id === ANOMALY_LAYER_ID) {
        this.map.addLayer(
            {
              'id': ANOMALY_LAYER_ID,
              'type': 'circle',
              'source': 'anomaly-vector',
              'source-layer': 'anomaly',
              'paint': {
                'circle-color': ANOMALY_COLOR,
                'circle-stroke-color': '#808080',
                'circle-stroke-width': 1,
                'circle-opacity': 0.9
              }
            }
        )
      } else if (id === INCIDENT_LAYER_ID) {
        this.map.addLayer(
            {
              'id': INCIDENT_LAYER_ID,
              'type': 'fill',
              'source': 'incident-vector',
              'source-layer': 'incident',
              'minzoom': 5,
              'maxzoom': 22,
              'paint': {
                'fill-color': INCIDENT_COLOR,
                'fill-opacity': 0.5
              }
            })
        this.map.addLayer(
            {
              'id': INCIDENT_LAYER_ID_SYMBOL,
              'type': 'symbol',
              'source': 'incident-vector',
              'source-layer': 'incident',
              'layout': {
                'icon-image':  'incident-icon',
                'icon-overlap': 'always'
              }
            }
        )
      }
    },
    removeLayer(id) {
      this.map.removeLayer(id)
      if (id === INCIDENT_LAYER_ID) {
        this.map.removeLayer(INCIDENT_LAYER_ID_SYMBOL)
      }
    },
    async setProfiles() {
      authorizedFetch('/api/incident/profiles')
          .then(response => response.json())
          .then((data) => {
            this.profiles = data
            this.profiles.forEach(profile => {
              profile.checked = true;
            })
          });
    },
    async loadIncidents() {
      authorizedFetch('/api/incidents/features')
          .then(response => response.json())
          .then((data) => {
            let features = [];
            for (let i = 0; i < data.length; i++) {
              let feature = JSON.parse(data[i]);

              let coordinates = feature.geometry.coordinates;
              if (coordinates[0] > 180) {
                feature.geometry = feature.properties.valid_geom;
              }
              features.push(feature);
            }
            console.log("features: " + features.length);
            this.checkSource("incident");
            this.map.getSource("incident").setData({
              "type": "FeatureCollection",
              "features": features
            });
          });
    },
    async loadChartValues(hours, change) {
      this.loaded = false;
      authorizedFetch('/api/incident/count/' + hours + '/30/' + this.consecutive)
          .then((response) => response.json())
          .then((data) => {
            this.setChartData(data, change)
            this.loaded = true
          });
    },
    async loadChartValuesInTimeRange(hours) {
      this.loaded = false
      authorizedFetch('/api/incident/count/' + hours + '/' + this.consecutive + '/' + this.date + '/' + this.endDate)
          .then((response) => response.json())
          .then((data) => {
            this.setChartData(data, false)
            this.loaded = true
          });
    },
    setChartData(data, change) {
      let labelArray = [];
      let incidentValues = [];
      let anomalyValues = [];
      for (let i = 0; i < data.length; i++) {
        let feature = data[i];
        labelArray.push(feature.time);
        incidentValues.push(feature.incidents);
        anomalyValues.push(feature.anomalies);
      }
      this.chartData = {
        labels: labelArray,
        datasets: [
          {
            label: "Anomaly",
            backgroundColor: ANOMALY_COLOR,
            data: anomalyValues
          },
          {
            label: "Incidents",
            backgroundColor: INCIDENT_COLOR,
            data: incidentValues
          }
        ]
      }
      if (change) {
        this.dateSelect(data.length - 1)
      }
    },
    checkSource(id) {
      if (this.map.getSource(id) === undefined) {
        this.map.addSource(id, {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });
      }
    },
    updateValues(value) {
      this.radius = value.radius;
      this.minutes = value.minutes;
      this.vessels = value.vessels;
      this.consecutive = value.consecutive;
      this.setTiles();
      this.loadChartValues(this.hours, false);
    },
    layerEvent(layer) {
      if (layer.value) {
        this.addLayer(layer.id);
      } else {
        this.removeLayer(layer.id);
      }
    },
    anomalyFilter(valueSet) {
      let values = Array.from(valueSet)
      this.map.setFilter(ANOMALY_LAYER_ID,
            [
              "any",
              ["in", ["get", "anomaly_reason"], ["literal", values]]
            ]
      );
    },
    incidentProfileFilter(valueSet) {
      let values = Array.from(valueSet)
      this.map.setFilter(INCIDENT_LAYER_ID,
          [
            "any",
            ["in", ["get", "profile"], ["literal", values]]
          ]
      );
      this.map.setFilter(INCIDENT_LAYER_ID_SYMBOL,
          [
            "any",
            ["in", ["get", "profile"], ["literal", values]]
          ]
      );
    },
    setTiles() {
      this.map.getSource('anomaly-vector').setTiles([window.location.origin + '/vector/anomaly/'
          + this.date + '/' + this.endDate + '/' + this.consecutive + '/{z}/{x}/{y}']);
      this.map.getSource('incident-vector').setTiles([window.location.origin + '/vector/incident/' +
      '' + this.date + '/' + this.endDate + '/{z}/{x}/{y}']);
    },
    async setMmsi(mmsi) {
      if (mmsi === null || mmsi === undefined) {
        this.mmsiAnomalies = {
          'type': 'FeatureCollection',
          'features': []
        }
        this.updateMmsiSource()
        this.countedMmsiAnomalies = undefined
        return
      }
      try {
            const data = await api('/anomaly/geojson/mmsi/' + mmsi + '/' + this.consecutive +'/' + this.date + '/' + this.endDate);
            let features = []
            for (let i = 0; i < data.length; i++) {
              try {
                  let feature = JSON.parse(data[i])

                  let coordinates = feature.geometry.coordinates
                  if (coordinates[0] > 180) {
                      feature.geometry = {
                          'type': feature.properties.valid_geom.type,
                          'coordinates': feature.properties.valid_geom.coordinates
                      }
                  }
                  features.push(feature)
              } catch (e) {
                  console.log(e)
              }
          }
          this.mmsiAnomalies = {
              'type': 'FeatureCollection',
              'features': features
          }
          this.countedMmsiAnomalies = features.length
          this.updateMmsiSource()
          this.fitToBounds(features)

        } catch (e) {
          console.log(e)
        }
     /* fetch('/anomaly/geojson/mmsi/' + mmsi + '/' + this.consecutive +'/' + this.date + '/' + this.endDate)
          .then(response => response.json())
          .then((data) => {
            let features = []
            for (let i = 0; i < data.length; i++) {
              try {
                let feature = JSON.parse(data[i])

                let coordinates = feature.geometry.coordinates
                if (coordinates[0] > 180) {
                  feature.geometry = {
                    'type': feature.properties.valid_geom.type,
                    'coordinates': feature.properties.valid_geom.coordinates
                  }
                }
                features.push(feature)
              } catch (e) {
                console.log(e)
              }
            }
            this.mmsiAnomalies = {
              'type': 'FeatureCollection',
              'features': features
            }
            this.countedMmsiAnomalies = features.length
            this.updateMmsiSource()
            this.fitToBounds(features)

          })*/
    },
    updateMmsiSource() {
      let sourceId = MapService.getMmsiSource()
      let source = this.map.getSource(sourceId)

      if (source === undefined) {
        this.map.addSource(sourceId, {
          'type': 'geojson',
          'data': this.mmsiAnomalies
        })
      } else {
        this.map.getSource(sourceId).setData(this.mmsiAnomalies)
      }
    },
    fitToBounds(features) {
      let bounds = new maplibregl.LngLatBounds();

      features.forEach(function(feature) {
        bounds.extend(feature.geometry.coordinates);
      });

      this.map.fitBounds(bounds);
    },
    getStats() {
      fetch('/aisd/stats/' + this.date)
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            this.stats = data
          })
    },
    getExclusionAreas() {
      authorizedFetch('api/incident/exclusion-areas')
          .then((response) => response.json())
          .then((data) => {
            let features = []
            console.log("YO YO YO; " + data)
            for (let i = 0; i < data.length; i++) {
              try {
                let feature = JSON.parse(data[i])
                features.push(feature)
              } catch (e) {
                console.log(e)
              }
            }
            this.exclusionAreas = {
              'type': 'FeatureCollection',
              'features': features
            }
            console.log(this.exclusionAreas)
            this.map.getSource(MapService.getExclusionSource()).setData(this.exclusionAreas)
          })
    }
  },
  created() {
    this.setProfiles()
    this.getExclusionAreas()
  },
  watch: {
    radius(newRadius) {
      console.log("should change tiles to reflect: " + newRadius)
    },
    mmsi(newMmsi) {
      console.log("new: " + newMmsi)
    }
  },
  mounted() {
    this.setupMap()
    this.loadChartValues(this.hours, false)
    Auth.currentUserInfo().then(result => {
      console.log(result)
      this.user = result
    })
  }
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
  height: 80vh;
}
#lineContainer {
  width: 100%;
  height: 8vh;
}
#app {
  text-align: left;
}

</style>

