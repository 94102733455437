<template>
  <amplify-sign-out class="signout-container"></amplify-sign-out>
</template>

<script>
export default {
  name: "sign_out.vue"
}
</script>

<style scoped>
.signout-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 8px;
  --amplify-primary-color: #2196F3;
  z-index: 1;
}

</style>