<template>
  <v-data-table-server
      :items-per-page="itemsPerPage"
      :headers="headers"
      :items-length="totalItems"
      :items="items"
      :loading="loading"
      item-value="id"
      class="elevation-1"
      @update:options="updateTable"
  >
    <!-- eslint-disable -->
    <template #item.actions="{ item }">
      <v-icon
          size="small"
          class="me-2"
          @click="viewIncident(item.value)"
      >
        mdi-magnify
      </v-icon>
    </template>

  </v-data-table-server>
</template>

<script>
export default {
  name: "IncidentTable",
  props: [ "headers", "items", "itemsPerPage", "totalItems", "loading"],
  methods: {
    viewIncident(id) {
      this.$emit("viewIncidentId", id)
    },
    updateTable(options) {
      this.$emit("updateTable", options)
    }
  }
}
</script>

<style scoped>

</style>