<template>
  <div class="login">
      <amplify-authenticator
              @authStateChange="handleAuthStateChange"
      >
      </amplify-authenticator>
  </div>
</template>

<script>
import { Auth } from "@aws-amplify/auth";
import { AmplifyAuthenticator} from "@aws-amplify/ui-vue";


export default {
    name: 'login-view',
    components: {
        AmplifyAuthenticator
    },
    methods: {
        async handleAuthStateChange(state) {
            if (state === "signedin") {
                const user = await Auth.currentAuthenticatedUser();
                const jwtToken = user.signInUserSession.accessToken.jwtToken;
                this.$store.commit("setIsAuthenticated", true);
                this.$store.commit("setToken", jwtToken);
                this.$router.push('/')
            }
        }
    }
}
</script>