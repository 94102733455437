<template>
  <v-navigation-drawer class="text-light-blue">
    <v-divider></v-divider>
    <br/>
    <IncidentProfileFilter :incidentProfiles="incidentProfiles"
                           @incidentProfileFilter="incidentProfileFilter">
    </IncidentProfileFilter>
    <v-divider></v-divider>
    <br />
    <JsonCSV :data="incidents" :labels="labels" name="incidents.csv">
      <v-list-item>
        <v-list-item-content>
        <v-btn color="info" prepend-icon="mdi-download">
          Download as CSV
        </v-btn>
        </v-list-item-content>
      </v-list-item>
    </JsonCSV>
  </v-navigation-drawer>
</template>

<script>
import IncidentProfileFilter from "@/components/IncidentProfileFilter";
import JsonCSV from "vue-json-csv";

export default {
  name: "ExportNavBar",
  components: { IncidentProfileFilter, JsonCSV },
  props: ["title", "incidentProfiles", "incidents", "labels"],
  methods: {
    incidentProfileFilter(incidentProfile) {
      this.$emit('incidentProfileFilter', incidentProfile)
    }
  }
}
</script>

<style scoped>
.map-overlay {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  width: 100%;
}

.map-overlay .map-overlay-inner {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.map-overlay-inner fieldset {
  border: none;
  padding: 0;
  margin: 0 0 10px;
}

.map-overlay-inner fieldset:last-child {
  margin: 0;
}

.map-overlay-inner select {
  width: 100%;
}

.map-overlay-inner label {
  display: block;
  font-weight: bold;
  margin: 0 0 5px;
}

.map-overlay-inner button {
  background-color: cornflowerblue;
  color: white;
  display: inline-block;
  height: 20px;
  border: none;
  cursor: pointer;
}

.map-overlay-inner button:focus {
  outline: none;
}

.map-overlay-inner button:hover {
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
}
</style>