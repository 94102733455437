<template>
  <v-main>
  <v-table >
    <thead>
    <tr>
      <th class="text-left">
        ID
      </th>
      <th class="text-left">
        Name
      </th>
      <th class="text-left">
        Radius
      </th>
      <th class="text-left">
        Vessels
      </th>
      <th class="text-left">
        Time in Minutes
      </th>
      <th class="text-left">
        E-Mail subscription
      </th>
      <th class="text-left">
        SMS subscription
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="profile in profiles"
        :key="profile.id"
    >
      <td>{{ profile.id }}</td>
      <td>{{ profile.name }}</td>
      <td>{{ profile.radius }}</td>
      <td>{{ profile.vessels }}</td>
      <td>{{ profile.timeInMinutes }}</td>
      <td>
        <v-checkbox
            @change="emailChange($event, profile)"
            v-model="profile.emailSelected">
        </v-checkbox>
      </td>
      <td>
        <v-checkbox
            @change="smsChange($event, profile)"
            v-model="profile.smsSelected">
        </v-checkbox>
      </td>
    </tr>
    </tbody>
  </v-table>
  </v-main>
</template>

<script>
import { Auth } from "aws-amplify";
import SubscriptionService from "@/services/SubscriptionService";
import api from "@/services/Api";

export default {
  name: "SubscriptionView",
  data() {
    return {
      user: '',
      profiles: [],
      subscriptions: []
    }
  },
  methods: {
    async setProfiles() {
        const data = await api('/incident/profiles')
        this.profiles = data
    },
    async setSubscriptions(user) {
        const data = await api('/subscribe/subscriptions/' + user)
        this.subscriptions = data
        this.updateProfiles()
    },
    updateProfiles() {
      const pIdsEmail = new Set()
      const pIdsSms = new Set()
      this.subscriptions.forEach(s => {
        if (s.email) {
          pIdsEmail.add(s.profileId)
        }
        if (s.sms) {
          pIdsSms.add(s.profileId)
        }
      })
      this.profiles.forEach((profile, index) => {
        profile.emailSelected = pIdsEmail.has(profile.id)
        profile.smsSelected = pIdsSms.has(profile.id)
        this.profiles[index] = profile
        console.log(profile)
      })
    },
    smsChange(event, profile) {
      console.log(event)
      console.log(profile.smsSelected)
      SubscriptionService.saveSub({
        username: this.user,
        profileId: profile.id,
        sms: profile.smsSelected,
        email: profile.emailSelected
      })
    },
    emailChange(event, profile) {
      console.log(event)
      console.log(profile.emailSelected)
      SubscriptionService.saveSub({
        username: this.user,
        profileId: profile.id,
        sms: profile.smsSelected,
        email: profile.emailSelected
      })
    }
  },
  mounted() {
    Auth.currentUserInfo().then(result => {
      console.log(result.username)
      this.user = result.username
    })
  },
  created() {
    this.setProfiles();
  },
  watch: {
    user() {
      this.setSubscriptions(this.user)
    }
  }
}
</script>

<style scoped>
.check-button {
  background-color: #2c3e50;
}
</style>