<template>
<div>
  <nav id="filter-group" class="filter-group">
    <div v-for="layer in layers" v-bind:key="layer.id" @click="check(layer)">
      <input type="checkbox" id="layer.id" name="layer.name" v-model="layer.value" >
      <label>{{ layer.name }}</label>
    </div>
    <div class="reason-list" @click="toggleAnomalyReasonFilter">
      <input type="checkbox" id="show-reasons" v-model="show">
      <label>Filter anomaly reasons</label>
    </div>
    <nav class="nav-anomaly" id="reason-list" v-if="show">
      <div class="reason-list" v-for="reason in anomalyReasons" v-bind:key="reason.id"  @click="toggleAnomalyReason(reason)">
        <input type="checkbox" id="reason.id" name="reason.name" v-model="reason.checked">
        <label>{{ reason.name }}</label>
      </div>
    </nav>
    <IncidentProfileFilter
                           :incidentProfiles="incidentProfiles"
                           @incidentProfileFilter="toggleIncidentProfileFilter">
    </IncidentProfileFilter>
  </nav>
</div>
</template>

<script>

import IncidentProfileFilter from "@/components/IncidentProfileFilter";

export default {
  name: "LayerSelector",
  components: {IncidentProfileFilter},
  props: ["layers", "incidentProfiles"],
  data() {
    return {
      checkedNames: [],
      show :false,
      anomalyReasons: [
        { id: 1, name: 'Position', values: [1,3,5,7,9,11,13,15], checked: true },
        //{ id: 2, name: 'Speed over Ground', values: [4,5,6,7,12,13,14,15], checked: true },
        //{ id: 3, name: 'Course over Ground', values: [2,3,6,7,10,11,14,15], checked: true },
        { id: 4, name: 'Position Jump', values: [8,9,10,11,12,13,14,15], checked: true}
      ],
      showIncidentProfiles: false
    }
  },
  methods: {
    check(layer) {
      layer.value = !layer.value
      this.$emit("layerEvent", layer)
    },
    toggleAnomalyReasonFilter() {
      this.show = !this.show
      if (!this.show) {
        this.anomalyReasons.forEach(reason => {
          reason.checked = true;
        })
        this.$emit("anomalyFilter", this.getFilterValues())
      }
    },
    toggleAnomalyReason(reason) {
      reason.checked = !reason.checked
      this.$emit("anomalyFilter", this.getFilterValues())
    },
    toggleIncidentProfileFilter(incidentProfileFilter) {
      this.$emit("incidentProfileFilter", incidentProfileFilter)
    },
    getFilterValues: function () {
      let filterValues = new Set()
      this.anomalyReasons.forEach(reason => {
        if (reason.checked) {
          reason.values.forEach(v => {
            filterValues.add(v)
          })
        }
      })
      return filterValues;
    },
    getIncidentProfileFilterValues: function () {
      let filterValues = new Set()
      this.incidentProfiles.forEach(profile => {
        if (profile.checked) {
          filterValues.add(profile.id)
        }
      })
      return filterValues;
    }
  }
}

</script>

<style scoped>
.filter-group {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  z-index: 1;
  border-radius: 3px;
  color: #2196F3;
  padding: 0;
}

.filter-group input[type='checkbox']:first-child + label {
  border-radius: 3px 3px 0 0;
}

.filter-group label:last-child {
  border-radius: 0 0 3px 3px;
  border: none;
}

.filter-group input[type='checkbox'] {
  display: none;
}

.filter-group input[type='checkbox'] + label {
  background-color: #fff;
  display: block;
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.filter-group input[type='checkbox'] + label {
  background-color: #fff;
  text-transform: capitalize;
}

.filter-group input[type='checkbox'] + label:hover,
.filter-group input[type='checkbox']:checked + label {
  background-color: #ADD8E6;
}

.filter-group input[type='checkbox']:checked + label:before {
  content: '✔';
  margin-right: 5px;
}

#reason-list {
  background-color: #ADD8E6;
  z-index: 1;
  bottom: 0px;
  left: 100%;
  font-family: 'Work Sans', sans-serif;
}
#reason-list a {
  color: white;
  height: 30px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}
#reason-list a:last-child {
  border: none;
}
.reason-list input[type='checkbox'] {
  display: none;
}

.reason-list input[type='checkbox'] + label {
  background-color: #fff;
  display: block;
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.reason-list input[type='checkbox'] + label {
  background-color: #fff;
  text-transform: capitalize;
}

.reason-list input[type='checkbox'] + label:hover,
.reason-list input[type='checkbox']:checked + label {
  background-color: #ADD8E6;
}

.reason-list input[type='checkbox']:checked + label:before {
  content: '✔';
  margin-right: 5px;
}
.nav-anomaly {
  padding: 0px;
}
</style>