<template>
  <v-app>
    <NavigationComponent></NavigationComponent>
    <router-view/>
  </v-app>
</template>

<style>

nav {
  color: #2196F3;
  padding: 0h 10px 3vh 10px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}
.text-light-blue {
  color: #2196F3;
}
</style>
<script>
import NavigationComponent from "@/components/NavigationComponent";
export default {
  components: {NavigationComponent}
}
</script>