<template>
  <RangeSelector @range="rangeSelect" @dateRange="dateRange">
  </RangeSelector>
  <LineChart :chart-data="chartData" @clicked="clicked" v-if="loaded"></LineChart>
  <div class="center" v-if="!loaded">
    <v-progress-circular align-center indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
import RangeSelector from "@/components/RangeSelector";
import LineChart from "@/components/Line"

export default {
  name: "DataSelection",
  props: ["chartData", "loaded"],
  components: {RangeSelector, LineChart},
  methods: {
    rangeSelect(range) {
      this.$emit('range', range)
    },
    dateRange(date) {
      this.$emit('date', date)
    },
    clicked(index) {
      this.$emit('clicked', index)
    }
  }
}
</script>

<style scoped>
.center {
  position: absolute;
  left: 50%;
}
</style>