import store from '@/store';

export default async function api(url, options = {}) {
    const token = store.getters.getToken;
    if (token) {
        if (!options.headers) {
            options.headers = {};
        }
        options.headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch('api' + url, options);
    if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
    }

    return response.json();
}

