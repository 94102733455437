<template>
  <div id="time-range-select">
    <v-row>
      <v-col class="text-light-blue">
        <Datepicker v-model="date" range type="date" format="dd/MM/yyyy"></Datepicker>
      </v-col>
      <v-col class="text-light-blue">
        <v-btn-toggle
              v-model="range"
              color="#ADD8E6"
              group>
          <v-btn v-for="(option, index) in options" :key="index" color="info" class="text-light-blue">
            {{ option.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "RangeSelector",
  components: { Datepicker },
  data() {
    return {
      options: [
        {'text': '1 Hour', value: 1, id: 0},
        {'text': '6 Hours', value: 6, id: 1},
        {'text': '12 Hours', value: 12, id: 2},
        {'text': '1 Day', value: 24, id: 3},
        {'text': '7 Days', value: (24*7), id: 4},
        {'text': '30 days', value: (24*7*30), id: 5}
      ],
      range: 3,
      date: {}
    }
  },
  watch: {
    range(newRange) {
      this.$emit('range', this.options[newRange])
    },
    date(newDate) {
      this.$emit('dateRange', newDate)
    }
  }
}
</script>

<style scoped>
#time-range-select {
  padding: 10px;
}
.dp__theme_light {
  --dp-text-color: #2196F3;
  --dp-icon-color: #2196F3;
}
</style>