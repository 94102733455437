import { Auth } from "aws-amplify";

const UserService = {
    getSession: async() => {
        let promise = Auth.currentSession()
        promise.then(
            function (data) {
                console.log(JSON.stringify(data));
                return data;
            },
            function(error) {
                console.error(error)
            }
        )
    },
    getJwtToken: async() => {
        let promise = Auth.currentSession()

        promise.then(
            function (data) {
                return data.getAccessToken().getJwtToken()
            },
            function(error) {
                console.error(error)
            }
        )
    },
    async getUserInfo() {
        let promise = Auth.currentUserInfo()
        promise.then(
            function(data) {
                console.log(JSON.stringify(data));
                return data;
            },
            function(error) {
               console.log(error);
            }
        );
    }
}
export default UserService