<template>
    <div class="label-container">
        <div id="label">
            Counted distinct mmsis: {{ mmsi }} from {{ fromTime }} - {{ toTime }}
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "DistinctMmsiBar.vue",
    props: ["startDate"],
    data() {
        return {
            stats: undefined,
            date: undefined
        }
    },
    methods: {
        getStats() {
            fetch('/aisd/stats/' + this.date)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    this.stats = data
                })
        }
    },
    watch: {
        startDate(val) {
            console.log('start date changed: ' + val)
            this.date = moment(val).format('YYYY-MM-DDTHH:mm:ss')
            this.getStats()
        }
    },
    computed: {
        mmsi() {
            if (this.stats === undefined) {
                return ""
            }
            return this.stats.distinctMmsis < 0 ? "N/A" : this.stats.distinctMmsis;
        },
        fromTime() {
            if (this.stats === undefined) {
                return ""
            }
            return moment(this.stats.fromTime).utc().format('YYYY-MM-DD HH:mm');
        },
        toTime() {
            if (this.stats === undefined) {
                return ""
            }
            return moment(this.stats.toTime).utc().format('YYYY-MM-DD HH:mm');
        }
    }
}
</script>

<style scoped>
.label-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
}

.label-container > * {
    background-color: hsla(0,0%,100%,.5);
    font-size: 11px;
    line-height: 18px;
    display: block;
    margin: 0;
    padding: 5px 10px;
}
</style>

