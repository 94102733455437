import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../components/Login.vue'
import store from '../store/index.js'
import { Hub } from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import MapViewLibre from "@/views/MapViewLibre";
import UserService from "@/services/UserService";
import axios from "axios";
import SubscriptionView from "@/views/SubscriptionView";
import IncidentView from "@/views/IncidentView";
import ListIncidentView from "@/views/ListIncidentView";

let user;


function getUser() {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data);
      let jwtToken = UserService.getJwtToken()
      console.log(jwtToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + jwtToken;
      return data;
    }
  }).catch(() => {
    store.commit('setUser', null);
    return null;
  });
}

Hub.listen("auth", async (data) => {
  if (data.payload.event === 'signOut'){
    user = null;
    store.commit('setUser', null);
    await router.push({path: '/login'});
  } else if (data.payload.event === 'signIn') {
    user = await getUser();
    let jwtToken = UserService.getJwtToken()
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwtToken;
    await router.push({path: '/'});
  }
});

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'Map',
    component: MapViewLibre,
    meta: { requiresAuth: true}
  },
  {
    path: '/vIncident/:id',
    name: 'Incident',
    component: IncidentView,
    //props: route => ({ incident: route.params.id }),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/listIncidents',
    name: 'Incidents',
    component: ListIncidentView,
    meta: { requiresAuth: true}
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: SubscriptionView,
    meta: { requiresAuth: true}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser();
    if (!user) {
      return next({
        path: '/login'
      });
    }
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    await store.dispatch('setToken', jwtToken);
    // Set the JWT token in the request headers
    axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
  }
  return next()

});

export default router