<template>
  <ExportNavBar :incidentProfiles="profiles" :incidents="incidents" :labels="labels"
                @incidentProfileFilter="incidentProfileFilter"></ExportNavBar>
  <v-main>
    <IncidentTable
      :itemsPerPage="itemsPerPage"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :loading="loading"
      @viewIncidentId="viewIncident"
      @updateTable="getIncidents"
    ></IncidentTable>
  </v-main>
</template>

<script>

import IncidentTable from "@/components/IncidentTable";
import ExportNavBar from "@/components/ExportNavBar";
import api from "@/services/Api";
export default {
  name: "ListIncidentView",
  components: {IncidentTable, ExportNavBar},
  data() {
    return {
      incidents: [],
      itemsPerPage: 25,
      headers: [
        {
          title: 'Incident ID',
          align: 'start',
          key: 'id',
        },
        { title: 'Profile Name', key: 'profileName' },
        { title: 'Start Time', key: 'startTime'},
        { title: 'End Time', key: 'endTime' },
        { title: 'Actions', key: 'actions', sortable: false }
      ],
      labels: ['Incident ID', 'Profile ID', 'Profile Name', 'Start Time', 'End Time'],
      loading: true,
      totalItems: 0,
      items: [],
      profiles: []
    }
  },
  methods: {
    async loadAllIncidents() {
      this.loading = true
      try {
          const data = await api('/incident/');
          this.incidents = data
          this.loading = false
          this.totalItems = this.incidents.length
          this.getIncidents(({ page: 1, itemsPerPage: 25, sortBy: [] }))
        } catch (error) {
           console.error(error)
        }
    },
    getIncidents({ page, itemsPerPage, sortBy }) {
      const start = (page -1) * itemsPerPage
      const end = start + itemsPerPage
      this.items = this.incidents;
      if (sortBy.length) {
        const sortKey = sortBy[0].key
        const sortOrder = sortBy[0].order
        this.items.sort((a, b) => {
          let aValue = a[sortKey]
          let bValue = b[sortKey]
          if (sortKey.includes('Time')) {
            aValue = new Date(aValue)
            bValue = new Date(bValue)
          }
          return sortOrder === 'desc' ? bValue - aValue : aValue - bValue
        })
      }
      this.items = this.items.slice(start, end)
    },
    viewIncident(id) {
      console.log('should show. '+ id)
      window.open('/vIncident/' +id)
    },
    async loadProfiles() {
        try {
            const data = await api('/incident/profiles')
            this.profiles = data
            this.profiles.forEach(profile => {
                profile.checked = true
            })
        } catch (error) {
            console.log(error)
        }
    },
    incidentProfileFilter(profileFilter) {
      let profileIds = Array.from(profileFilter).join(',')
      this.loadIncidentsOnProfiles(profileIds)
    },
    async loadIncidentsOnProfiles(profileIds) {
      this.loading = true
        try {
            const data = await api('/incident/profileIds/' + profileIds)
            this.incidents = data
            this.loading = false
            this.totalItems = this.incidents.length
            console.log(this.totalItems)
            this.getIncidents(({ page: 1, itemsPerPage: 25, sortBy: [] }))
        } catch (error) {
            console.error(error)
        }
    }
  },
  created() {
    this.loadAllIncidents()
    this.loadProfiles()
  }
}
</script>

<style scoped>

</style>