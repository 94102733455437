import store from "@/store";

export default function authorizedFetch(url, options = {}) {
    const token = store.getters.getToken;
    options.headers = options.headers || {};

    // set Authorization header with token
    options.headers.Authorization = `Bearer ${token}`;

    return fetch(url, options);
}